import React from "react"
import { PageTitle } from "../../components/copy"
import Gallery from "../../components/gallery"
import "./index.scss"

const GalleryScreen = ({ data }) => (
  <div className="galleryScreen u-paddingHorizontal16">
    <PageTitle
      className="u-marginTop48"
      title="Gallery"
    />
    <Gallery
      className="u-marginTop40"
      galleries={data.images.reverse()}
    />
  </div>
)

export default GalleryScreen
